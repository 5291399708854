* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  .modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    box-shadow: 4px 4px grey;
    background-color: white;
    z-index: 10;
    /* z-index: 100000000000; */
  }
  
  @-webkit-keyframes travel {
    from { left: 0;     }
    to   { left: calc(100% - 150px); }
  }
  
  @-webkit-keyframes bounce {
    from, to  {
      bottom: 0;
      -webkit-animation-timing-function: ease-out;
      /* -webkit-animation-timing-function: linear; */
    }
    50% {
      bottom: calc(100% - 150px);
      -webkit-animation-timing-function: ease-in;
      /* -webkit-animation-timing-function: linear; */
    }
  }
  
  @-webkit-keyframes spin {
    from {                                     }
    to   { -webkit-transform: rotateZ(360deg); }
    /* to   { } */

  }
  
  /* keyframes definition for other browsers */
  
  @keyframes travel {
    from { left: 0;     }
    to   { left: calc(100% - 150px); }
  }
  
  @keyframes bounce {
    from, to  {
      bottom: 0;
     animation-timing-function: ease-out;
     /* animation-timing-function: linear; */
    }
    50% {
      bottom: calc(100% - 150px);
      animation-timing-function: ease-in;
      /* animation-timing-function: linear; */
    }
  }
  
  @keyframes spin {
    from {
      -moz-transform: rotate(0);
      -o-transform: rotate(0);
      -ms-transform: rotate(0);
      transform: rotate(0);
    }
    to {
      -moz-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
      /* -moz-transform: rotate(0);
      -o-transform: rotate(0);
      -ms-transform: rotate(0);
      transform: rotate(0); */
    }
  }
  
  /* styles for the stage and animated elements */
  
  .stage {
    position: relative;
    /* margin: 1em auto; */
    /* width: 660px;
    height: 240px; */
    /* border: 2px solid #666; */
    /* background-color: blue; */
    height: 100%;
  }
  
  .traveler {
    position: absolute;
    width: 20px;
    height: 100%;
  
    -webkit-animation-name: travel;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
    -webkit-animation-duration: 10s;
  
    animation-name: travel;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-duration: 10s;
  
    /* background: red; */
  }
  
  .bouncer {
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 10px;
    /* background: red; */
    background: -webkit-radial-gradient(60% 40%, circle, rgba(255,255,255,0.8), red 50%) red;
    background: -moz-radial-gradient(60% 40%, circle, rgba(255,255,255,0.8), red 50%) red;
    background: -o-radial-gradient(60% 40%, circle, rgba(255,255,255,0.8), red 50%) red;
    background: -ms-radial-gradient(60% 40%, circle, rgba(255,255,255,0.8), red 50%) red;
    background: radial-gradient(60% 40%, circle, rgba(255,255,255,0.8), red 50%) red;
  
    -webkit-animation-name: bounce, spin;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 10s;
  
    animation-name: bounce, spin;
    animation-iteration-count: infinite;
    animation-duration: 10s;
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  .firefly {
    position: fixed;
    left: 50%;
    top: 50%;
    width: 0.4vw;
    height: 0.4vw;
    margin: -0.2vw 0 0 9.8vw;
    animation: ease 200s alternate infinite;
    pointer-events: none;
  }
  .firefly::before, .firefly::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform-origin: -10vw;
  }
  .firefly::before {
    background: black;
    opacity: 0.4;
    animation: drift ease alternate infinite;
  }
  .firefly::after {
    background: white;
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow;
    animation: drift ease alternate infinite, flash ease infinite;
  }
  
  .firefly:nth-child(1) {
    animation-name: move1;
  }
  .firefly:nth-child(1)::before {
    animation-duration: 15s;
  }
  .firefly:nth-child(1)::after {
    animation-duration: 15s, 8757ms;
    animation-delay: 0ms, 3932ms;
  }
  
  @keyframes move1 {
    0% {
      transform: translateX(7vw) translateY(45vh) scale(0.31);
    }
    5.8823529412% {
      transform: translateX(-30vw) translateY(21vh) scale(0.73);
    }
    11.7647058824% {
      transform: translateX(-23vw) translateY(-49vh) scale(0.95);
    }
    17.6470588235% {
      transform: translateX(-15vw) translateY(-23vh) scale(0.79);
    }
    23.5294117647% {
      transform: translateX(31vw) translateY(-48vh) scale(0.42);
    }
    29.4117647059% {
      transform: translateX(-28vw) translateY(24vh) scale(0.82);
    }
    35.2941176471% {
      transform: translateX(6vw) translateY(28vh) scale(1);
    }
    41.1764705882% {
      transform: translateX(35vw) translateY(-41vh) scale(0.59);
    }
    47.0588235294% {
      transform: translateX(15vw) translateY(-18vh) scale(0.32);
    }
    52.9411764706% {
      transform: translateX(-49vw) translateY(-27vh) scale(0.37);
    }
    58.8235294118% {
      transform: translateX(49vw) translateY(-12vh) scale(0.28);
    }
    64.7058823529% {
      transform: translateX(-10vw) translateY(11vh) scale(0.8);
    }
    70.5882352941% {
      transform: translateX(-3vw) translateY(41vh) scale(0.34);
    }
    76.4705882353% {
      transform: translateX(-13vw) translateY(5vh) scale(0.51);
    }
    82.3529411765% {
      transform: translateX(-46vw) translateY(37vh) scale(0.36);
    }
    88.2352941176% {
      transform: translateX(-13vw) translateY(38vh) scale(0.37);
    }
    94.1176470588% {
      transform: translateX(-12vw) translateY(-41vh) scale(0.77);
    }
    100% {
      transform: translateX(-1vw) translateY(34vh) scale(0.98);
    }
  }
  .firefly:nth-child(2) {
    animation-name: move2;
  }
  .firefly:nth-child(2)::before {
    animation-duration: 18s;
  }
  .firefly:nth-child(2)::after {
    animation-duration: 18s, 7620ms;
    animation-delay: 0ms, 3298ms;
  }
  
  @keyframes move2 {
    0% {
      transform: translateX(27vw) translateY(17vh) scale(0.63);
    }
    4.1666666667% {
      transform: translateX(-17vw) translateY(4vh) scale(0.71);
    }
    8.3333333333% {
      transform: translateX(-25vw) translateY(-38vh) scale(0.74);
    }
    12.5% {
      transform: translateX(-7vw) translateY(6vh) scale(0.41);
    }
    16.6666666667% {
      transform: translateX(-6vw) translateY(-29vh) scale(0.57);
    }
    20.8333333333% {
      transform: translateX(-6vw) translateY(32vh) scale(0.71);
    }
    25% {
      transform: translateX(-40vw) translateY(6vh) scale(0.35);
    }
    29.1666666667% {
      transform: translateX(-6vw) translateY(-48vh) scale(0.96);
    }
    33.3333333333% {
      transform: translateX(14vw) translateY(22vh) scale(0.3);
    }
    37.5% {
      transform: translateX(25vw) translateY(40vh) scale(0.58);
    }
    41.6666666667% {
      transform: translateX(23vw) translateY(47vh) scale(0.97);
    }
    45.8333333333% {
      transform: translateX(-49vw) translateY(-2vh) scale(0.91);
    }
    50% {
      transform: translateX(-14vw) translateY(-33vh) scale(0.47);
    }
    54.1666666667% {
      transform: translateX(-38vw) translateY(-44vh) scale(0.62);
    }
    58.3333333333% {
      transform: translateX(23vw) translateY(-24vh) scale(0.54);
    }
    62.5% {
      transform: translateX(-8vw) translateY(-39vh) scale(0.45);
    }
    66.6666666667% {
      transform: translateX(21vw) translateY(-13vh) scale(0.89);
    }
    70.8333333333% {
      transform: translateX(-32vw) translateY(27vh) scale(0.3);
    }
    75% {
      transform: translateX(-7vw) translateY(45vh) scale(0.94);
    }
    79.1666666667% {
      transform: translateX(47vw) translateY(-19vh) scale(0.99);
    }
    83.3333333333% {
      transform: translateX(-49vw) translateY(-41vh) scale(0.43);
    }
    87.5% {
      transform: translateX(12vw) translateY(24vh) scale(0.66);
    }
    91.6666666667% {
      transform: translateX(25vw) translateY(-4vh) scale(0.66);
    }
    95.8333333333% {
      transform: translateX(-7vw) translateY(-39vh) scale(0.63);
    }
    100% {
      transform: translateX(4vw) translateY(-6vh) scale(0.53);
    }
  }
  .firefly:nth-child(3) {
    animation-name: move3;
  }
  .firefly:nth-child(3)::before {
    animation-duration: 10s;
  }
  .firefly:nth-child(3)::after {
    animation-duration: 10s, 10708ms;
    animation-delay: 0ms, 5610ms;
  }
  
  @keyframes move3 {
    0% {
      transform: translateX(6vw) translateY(-12vh) scale(0.26);
    }
    5.2631578947% {
      transform: translateX(-15vw) translateY(15vh) scale(0.96);
    }
    10.5263157895% {
      transform: translateX(-34vw) translateY(37vh) scale(0.91);
    }
    15.7894736842% {
      transform: translateX(-8vw) translateY(-9vh) scale(0.27);
    }
    21.0526315789% {
      transform: translateX(39vw) translateY(-47vh) scale(0.45);
    }
    26.3157894737% {
      transform: translateX(8vw) translateY(34vh) scale(0.44);
    }
    31.5789473684% {
      transform: translateX(30vw) translateY(21vh) scale(0.92);
    }
    36.8421052632% {
      transform: translateX(-26vw) translateY(-33vh) scale(0.36);
    }
    42.1052631579% {
      transform: translateX(8vw) translateY(23vh) scale(0.51);
    }
    47.3684210526% {
      transform: translateX(35vw) translateY(9vh) scale(0.81);
    }
    52.6315789474% {
      transform: translateX(48vw) translateY(19vh) scale(0.83);
    }
    57.8947368421% {
      transform: translateX(36vw) translateY(19vh) scale(0.52);
    }
    63.1578947368% {
      transform: translateX(0vw) translateY(-10vh) scale(0.66);
    }
    68.4210526316% {
      transform: translateX(-45vw) translateY(4vh) scale(0.98);
    }
    73.6842105263% {
      transform: translateX(-28vw) translateY(25vh) scale(0.81);
    }
    78.9473684211% {
      transform: translateX(-32vw) translateY(-31vh) scale(0.46);
    }
    84.2105263158% {
      transform: translateX(46vw) translateY(-15vh) scale(0.41);
    }
    89.4736842105% {
      transform: translateX(-46vw) translateY(-31vh) scale(0.31);
    }
    94.7368421053% {
      transform: translateX(-20vw) translateY(8vh) scale(0.68);
    }
    100% {
      transform: translateX(-5vw) translateY(39vh) scale(0.8);
    }
  }
  .firefly:nth-child(4) {
    animation-name: move4;
  }
  .firefly:nth-child(4)::before {
    animation-duration: 15s;
  }
  .firefly:nth-child(4)::after {
    animation-duration: 15s, 5804ms;
    animation-delay: 0ms, 7991ms;
  }
  
  @keyframes move4 {
    0% {
      transform: translateX(41vw) translateY(5vh) scale(0.59);
    }
    4.7619047619% {
      transform: translateX(9vw) translateY(48vh) scale(0.86);
    }
    9.5238095238% {
      transform: translateX(9vw) translateY(29vh) scale(0.49);
    }
    14.2857142857% {
      transform: translateX(-32vw) translateY(30vh) scale(0.42);
    }
    19.0476190476% {
      transform: translateX(-40vw) translateY(40vh) scale(0.52);
    }
    23.8095238095% {
      transform: translateX(-26vw) translateY(42vh) scale(0.71);
    }
    28.5714285714% {
      transform: translateX(10vw) translateY(-39vh) scale(0.74);
    }
    33.3333333333% {
      transform: translateX(31vw) translateY(-47vh) scale(0.38);
    }
    38.0952380952% {
      transform: translateX(-48vw) translateY(38vh) scale(0.55);
    }
    42.8571428571% {
      transform: translateX(-29vw) translateY(30vh) scale(0.97);
    }
    47.619047619% {
      transform: translateX(33vw) translateY(40vh) scale(0.37);
    }
    52.380952381% {
      transform: translateX(29vw) translateY(4vh) scale(0.75);
    }
    57.1428571429% {
      transform: translateX(-13vw) translateY(45vh) scale(0.82);
    }
    61.9047619048% {
      transform: translateX(41vw) translateY(-49vh) scale(0.48);
    }
    66.6666666667% {
      transform: translateX(-23vw) translateY(28vh) scale(0.29);
    }
    71.4285714286% {
      transform: translateX(1vw) translateY(-48vh) scale(0.88);
    }
    76.1904761905% {
      transform: translateX(-46vw) translateY(-4vh) scale(0.71);
    }
    80.9523809524% {
      transform: translateX(-10vw) translateY(-46vh) scale(0.33);
    }
    85.7142857143% {
      transform: translateX(4vw) translateY(16vh) scale(0.95);
    }
    90.4761904762% {
      transform: translateX(-12vw) translateY(-12vh) scale(0.31);
    }
    95.2380952381% {
      transform: translateX(41vw) translateY(6vh) scale(0.74);
    }
    100% {
      transform: translateX(14vw) translateY(10vh) scale(0.46);
    }
  }
  .firefly:nth-child(5) {
    animation-name: move5;
  }
  .firefly:nth-child(5)::before {
    animation-duration: 16s;
  }
  .firefly:nth-child(5)::after {
    animation-duration: 16s, 9090ms;
    animation-delay: 0ms, 6588ms;
  }
  
  @keyframes move5 {
    0% {
      transform: translateX(-22vw) translateY(-10vh) scale(0.59);
    }
    4.1666666667% {
      transform: translateX(22vw) translateY(33vh) scale(0.35);
    }
    8.3333333333% {
      transform: translateX(-45vw) translateY(13vh) scale(0.64);
    }
    12.5% {
      transform: translateX(16vw) translateY(-8vh) scale(0.37);
    }
    16.6666666667% {
      transform: translateX(-39vw) translateY(27vh) scale(0.41);
    }
    20.8333333333% {
      transform: translateX(9vw) translateY(18vh) scale(0.57);
    }
    25% {
      transform: translateX(-32vw) translateY(-25vh) scale(0.57);
    }
    29.1666666667% {
      transform: translateX(-41vw) translateY(31vh) scale(0.84);
    }
    33.3333333333% {
      transform: translateX(38vw) translateY(13vh) scale(0.67);
    }
    37.5% {
      transform: translateX(-14vw) translateY(39vh) scale(0.51);
    }
    41.6666666667% {
      transform: translateX(-29vw) translateY(21vh) scale(0.29);
    }
    45.8333333333% {
      transform: translateX(36vw) translateY(4vh) scale(0.37);
    }
    50% {
      transform: translateX(41vw) translateY(17vh) scale(0.81);
    }
    54.1666666667% {
      transform: translateX(40vw) translateY(-3vh) scale(0.95);
    }
    58.3333333333% {
      transform: translateX(6vw) translateY(25vh) scale(0.98);
    }
    62.5% {
      transform: translateX(9vw) translateY(-43vh) scale(0.29);
    }
    66.6666666667% {
      transform: translateX(48vw) translateY(36vh) scale(0.89);
    }
    70.8333333333% {
      transform: translateX(0vw) translateY(-47vh) scale(0.81);
    }
    75% {
      transform: translateX(43vw) translateY(11vh) scale(0.28);
    }
    79.1666666667% {
      transform: translateX(-44vw) translateY(-47vh) scale(0.42);
    }
    83.3333333333% {
      transform: translateX(-36vw) translateY(-45vh) scale(0.33);
    }
    87.5% {
      transform: translateX(38vw) translateY(-16vh) scale(0.36);
    }
    91.6666666667% {
      transform: translateX(-12vw) translateY(12vh) scale(0.88);
    }
    95.8333333333% {
      transform: translateX(-47vw) translateY(-35vh) scale(0.29);
    }
    100% {
      transform: translateX(-31vw) translateY(23vh) scale(0.64);
    }
  }
  .firefly:nth-child(6) {
    animation-name: move6;
  }
  .firefly:nth-child(6)::before {
    animation-duration: 16s;
  }
  .firefly:nth-child(6)::after {
    animation-duration: 16s, 10893ms;
    animation-delay: 0ms, 1167ms;
  }
  
  @keyframes move6 {
    0% {
      transform: translateX(-46vw) translateY(-18vh) scale(0.92);
    }
    4% {
      transform: translateX(10vw) translateY(32vh) scale(0.42);
    }
    8% {
      transform: translateX(-49vw) translateY(-32vh) scale(0.51);
    }
    12% {
      transform: translateX(43vw) translateY(-9vh) scale(0.32);
    }
    16% {
      transform: translateX(-31vw) translateY(-36vh) scale(0.37);
    }
    20% {
      transform: translateX(30vw) translateY(-44vh) scale(0.87);
    }
    24% {
      transform: translateX(-9vw) translateY(20vh) scale(0.97);
    }
    28% {
      transform: translateX(36vw) translateY(0vh) scale(0.49);
    }
    32% {
      transform: translateX(14vw) translateY(33vh) scale(0.41);
    }
    36% {
      transform: translateX(19vw) translateY(-28vh) scale(0.88);
    }
    40% {
      transform: translateX(-39vw) translateY(32vh) scale(0.96);
    }
    44% {
      transform: translateX(46vw) translateY(-30vh) scale(0.59);
    }
    48% {
      transform: translateX(-37vw) translateY(-20vh) scale(0.45);
    }
    52% {
      transform: translateX(-25vw) translateY(13vh) scale(0.48);
    }
    56% {
      transform: translateX(35vw) translateY(-18vh) scale(0.45);
    }
    60% {
      transform: translateX(-46vw) translateY(43vh) scale(0.92);
    }
    64% {
      transform: translateX(33vw) translateY(27vh) scale(0.43);
    }
    68% {
      transform: translateX(12vw) translateY(30vh) scale(0.97);
    }
    72% {
      transform: translateX(26vw) translateY(38vh) scale(0.51);
    }
    76% {
      transform: translateX(21vw) translateY(-24vh) scale(0.78);
    }
    80% {
      transform: translateX(-28vw) translateY(39vh) scale(0.87);
    }
    84% {
      transform: translateX(-44vw) translateY(-6vh) scale(0.54);
    }
    88% {
      transform: translateX(1vw) translateY(-35vh) scale(0.49);
    }
    92% {
      transform: translateX(0vw) translateY(-16vh) scale(0.45);
    }
    96% {
      transform: translateX(-26vw) translateY(3vh) scale(0.65);
    }
    100% {
      transform: translateX(-37vw) translateY(22vh) scale(0.33);
    }
  }
  .firefly:nth-child(7) {
    animation-name: move7;
  }
  .firefly:nth-child(7)::before {
    animation-duration: 10s;
  }
  .firefly:nth-child(7)::after {
    animation-duration: 10s, 5908ms;
    animation-delay: 0ms, 7621ms;
  }
  
  @keyframes move7 {
    0% {
      transform: translateX(45vw) translateY(24vh) scale(0.39);
    }
    4.7619047619% {
      transform: translateX(48vw) translateY(10vh) scale(0.31);
    }
    9.5238095238% {
      transform: translateX(-33vw) translateY(-15vh) scale(0.68);
    }
    14.2857142857% {
      transform: translateX(22vw) translateY(50vh) scale(0.47);
    }
    19.0476190476% {
      transform: translateX(-33vw) translateY(21vh) scale(0.67);
    }
    23.8095238095% {
      transform: translateX(-27vw) translateY(-7vh) scale(0.78);
    }
    28.5714285714% {
      transform: translateX(29vw) translateY(23vh) scale(0.8);
    }
    33.3333333333% {
      transform: translateX(-29vw) translateY(26vh) scale(0.64);
    }
    38.0952380952% {
      transform: translateX(20vw) translateY(-41vh) scale(0.56);
    }
    42.8571428571% {
      transform: translateX(-16vw) translateY(30vh) scale(0.51);
    }
    47.619047619% {
      transform: translateX(46vw) translateY(27vh) scale(0.78);
    }
    52.380952381% {
      transform: translateX(8vw) translateY(-29vh) scale(0.93);
    }
    57.1428571429% {
      transform: translateX(17vw) translateY(-40vh) scale(0.28);
    }
    61.9047619048% {
      transform: translateX(-14vw) translateY(14vh) scale(0.99);
    }
    66.6666666667% {
      transform: translateX(-33vw) translateY(0vh) scale(0.9);
    }
    71.4285714286% {
      transform: translateX(24vw) translateY(-8vh) scale(0.39);
    }
    76.1904761905% {
      transform: translateX(9vw) translateY(-14vh) scale(0.73);
    }
    80.9523809524% {
      transform: translateX(40vw) translateY(-26vh) scale(0.52);
    }
    85.7142857143% {
      transform: translateX(-7vw) translateY(31vh) scale(0.84);
    }
    90.4761904762% {
      transform: translateX(34vw) translateY(41vh) scale(0.67);
    }
    95.2380952381% {
      transform: translateX(37vw) translateY(30vh) scale(0.5);
    }
    100% {
      transform: translateX(48vw) translateY(8vh) scale(0.69);
    }
  }
  .firefly:nth-child(8) {
    animation-name: move8;
  }
  .firefly:nth-child(8)::before {
    animation-duration: 17s;
  }
  .firefly:nth-child(8)::after {
    animation-duration: 17s, 7870ms;
    animation-delay: 0ms, 2525ms;
  }
  
  @keyframes move8 {
    0% {
      transform: translateX(40vw) translateY(34vh) scale(0.37);
    }
    5.8823529412% {
      transform: translateX(8vw) translateY(-14vh) scale(0.66);
    }
    11.7647058824% {
      transform: translateX(29vw) translateY(-1vh) scale(0.96);
    }
    17.6470588235% {
      transform: translateX(-30vw) translateY(-47vh) scale(0.62);
    }
    23.5294117647% {
      transform: translateX(6vw) translateY(-34vh) scale(0.47);
    }
    29.4117647059% {
      transform: translateX(-6vw) translateY(-33vh) scale(0.53);
    }
    35.2941176471% {
      transform: translateX(-29vw) translateY(49vh) scale(0.4);
    }
    41.1764705882% {
      transform: translateX(10vw) translateY(30vh) scale(0.57);
    }
    47.0588235294% {
      transform: translateX(-12vw) translateY(-43vh) scale(0.28);
    }
    52.9411764706% {
      transform: translateX(-18vw) translateY(0vh) scale(0.96);
    }
    58.8235294118% {
      transform: translateX(-10vw) translateY(-9vh) scale(0.73);
    }
    64.7058823529% {
      transform: translateX(46vw) translateY(18vh) scale(0.96);
    }
    70.5882352941% {
      transform: translateX(-10vw) translateY(-11vh) scale(0.53);
    }
    76.4705882353% {
      transform: translateX(-9vw) translateY(-41vh) scale(0.5);
    }
    82.3529411765% {
      transform: translateX(3vw) translateY(-37vh) scale(0.82);
    }
    88.2352941176% {
      transform: translateX(-14vw) translateY(-22vh) scale(0.42);
    }
    94.1176470588% {
      transform: translateX(43vw) translateY(-4vh) scale(0.79);
    }
    100% {
      transform: translateX(-23vw) translateY(0vh) scale(0.76);
    }
  }
  .firefly:nth-child(9) {
    animation-name: move9;
  }
  .firefly:nth-child(9)::before {
    animation-duration: 18s;
  }
  .firefly:nth-child(9)::after {
    animation-duration: 18s, 9461ms;
    animation-delay: 0ms, 7884ms;
  }
  
  @keyframes move9 {
    0% {
      transform: translateX(-39vw) translateY(-37vh) scale(0.8);
    }
    5.2631578947% {
      transform: translateX(-22vw) translateY(18vh) scale(0.78);
    }
    10.5263157895% {
      transform: translateX(32vw) translateY(-6vh) scale(0.65);
    }
    15.7894736842% {
      transform: translateX(-15vw) translateY(35vh) scale(0.77);
    }
    21.0526315789% {
      transform: translateX(-28vw) translateY(24vh) scale(0.44);
    }
    26.3157894737% {
      transform: translateX(6vw) translateY(43vh) scale(0.54);
    }
    31.5789473684% {
      transform: translateX(-48vw) translateY(-19vh) scale(0.61);
    }
    36.8421052632% {
      transform: translateX(8vw) translateY(-27vh) scale(0.51);
    }
    42.1052631579% {
      transform: translateX(8vw) translateY(2vh) scale(0.36);
    }
    47.3684210526% {
      transform: translateX(38vw) translateY(20vh) scale(0.48);
    }
    52.6315789474% {
      transform: translateX(-25vw) translateY(-5vh) scale(0.74);
    }
    57.8947368421% {
      transform: translateX(-29vw) translateY(32vh) scale(0.91);
    }
    63.1578947368% {
      transform: translateX(12vw) translateY(34vh) scale(0.93);
    }
    68.4210526316% {
      transform: translateX(31vw) translateY(3vh) scale(0.8);
    }
    73.6842105263% {
      transform: translateX(22vw) translateY(18vh) scale(0.71);
    }
    78.9473684211% {
      transform: translateX(-44vw) translateY(-5vh) scale(0.48);
    }
    84.2105263158% {
      transform: translateX(-47vw) translateY(32vh) scale(0.61);
    }
    89.4736842105% {
      transform: translateX(-20vw) translateY(35vh) scale(0.27);
    }
    94.7368421053% {
      transform: translateX(-4vw) translateY(34vh) scale(0.6);
    }
    100% {
      transform: translateX(14vw) translateY(43vh) scale(0.68);
    }
  }
  .firefly:nth-child(10) {
    animation-name: move10;
  }
  .firefly:nth-child(10)::before {
    animation-duration: 16s;
  }
  .firefly:nth-child(10)::after {
    animation-duration: 16s, 8050ms;
    animation-delay: 0ms, 1013ms;
  }
  
  @keyframes move10 {
    0% {
      transform: translateX(16vw) translateY(36vh) scale(0.37);
    }
    5.8823529412% {
      transform: translateX(3vw) translateY(-37vh) scale(0.63);
    }
    11.7647058824% {
      transform: translateX(-46vw) translateY(36vh) scale(0.53);
    }
    17.6470588235% {
      transform: translateX(-29vw) translateY(8vh) scale(0.67);
    }
    23.5294117647% {
      transform: translateX(0vw) translateY(-7vh) scale(0.7);
    }
    29.4117647059% {
      transform: translateX(40vw) translateY(-34vh) scale(0.67);
    }
    35.2941176471% {
      transform: translateX(-31vw) translateY(-45vh) scale(0.3);
    }
    41.1764705882% {
      transform: translateX(-40vw) translateY(13vh) scale(0.72);
    }
    47.0588235294% {
      transform: translateX(-25vw) translateY(-39vh) scale(0.41);
    }
    52.9411764706% {
      transform: translateX(33vw) translateY(25vh) scale(0.92);
    }
    58.8235294118% {
      transform: translateX(17vw) translateY(8vh) scale(0.43);
    }
    64.7058823529% {
      transform: translateX(-6vw) translateY(-32vh) scale(0.66);
    }
    70.5882352941% {
      transform: translateX(47vw) translateY(-46vh) scale(0.75);
    }
    76.4705882353% {
      transform: translateX(0vw) translateY(9vh) scale(0.41);
    }
    82.3529411765% {
      transform: translateX(-3vw) translateY(0vh) scale(0.55);
    }
    88.2352941176% {
      transform: translateX(-47vw) translateY(-40vh) scale(0.95);
    }
    94.1176470588% {
      transform: translateX(-38vw) translateY(-42vh) scale(0.4);
    }
    100% {
      transform: translateX(-20vw) translateY(41vh) scale(0.59);
    }
  }
  .firefly:nth-child(11) {
    animation-name: move11;
  }
  .firefly:nth-child(11)::before {
    animation-duration: 14s;
  }
  .firefly:nth-child(11)::after {
    animation-duration: 14s, 8229ms;
    animation-delay: 0ms, 6020ms;
  }
  
  @keyframes move11 {
    0% {
      transform: translateX(50vw) translateY(-4vh) scale(0.29);
    }
    3.5714285714% {
      transform: translateX(28vw) translateY(26vh) scale(0.4);
    }
    7.1428571429% {
      transform: translateX(23vw) translateY(35vh) scale(0.32);
    }
    10.7142857143% {
      transform: translateX(-12vw) translateY(-18vh) scale(0.28);
    }
    14.2857142857% {
      transform: translateX(22vw) translateY(-14vh) scale(0.68);
    }
    17.8571428571% {
      transform: translateX(-25vw) translateY(-39vh) scale(0.51);
    }
    21.4285714286% {
      transform: translateX(50vw) translateY(23vh) scale(0.52);
    }
    25% {
      transform: translateX(-32vw) translateY(49vh) scale(0.3);
    }
    28.5714285714% {
      transform: translateX(-31vw) translateY(31vh) scale(0.69);
    }
    32.1428571429% {
      transform: translateX(41vw) translateY(-2vh) scale(0.52);
    }
    35.7142857143% {
      transform: translateX(-35vw) translateY(30vh) scale(0.4);
    }
    39.2857142857% {
      transform: translateX(-33vw) translateY(-11vh) scale(0.7);
    }
    42.8571428571% {
      transform: translateX(45vw) translateY(-13vh) scale(0.92);
    }
    46.4285714286% {
      transform: translateX(7vw) translateY(-26vh) scale(0.41);
    }
    50% {
      transform: translateX(-18vw) translateY(-11vh) scale(0.47);
    }
    53.5714285714% {
      transform: translateX(48vw) translateY(-4vh) scale(0.35);
    }
    57.1428571429% {
      transform: translateX(-40vw) translateY(-28vh) scale(0.86);
    }
    60.7142857143% {
      transform: translateX(3vw) translateY(41vh) scale(0.78);
    }
    64.2857142857% {
      transform: translateX(15vw) translateY(-17vh) scale(0.56);
    }
    67.8571428571% {
      transform: translateX(-5vw) translateY(42vh) scale(0.73);
    }
    71.4285714286% {
      transform: translateX(-16vw) translateY(48vh) scale(0.31);
    }
    75% {
      transform: translateX(22vw) translateY(22vh) scale(0.79);
    }
    78.5714285714% {
      transform: translateX(-46vw) translateY(17vh) scale(0.98);
    }
    82.1428571429% {
      transform: translateX(36vw) translateY(29vh) scale(0.63);
    }
    85.7142857143% {
      transform: translateX(-25vw) translateY(20vh) scale(0.27);
    }
    89.2857142857% {
      transform: translateX(-14vw) translateY(34vh) scale(0.37);
    }
    92.8571428571% {
      transform: translateX(-8vw) translateY(-31vh) scale(0.6);
    }
    96.4285714286% {
      transform: translateX(-36vw) translateY(-33vh) scale(0.35);
    }
    100% {
      transform: translateX(42vw) translateY(-12vh) scale(0.43);
    }
  }
  .firefly:nth-child(12) {
    animation-name: move12;
  }
  .firefly:nth-child(12)::before {
    animation-duration: 9s;
  }
  .firefly:nth-child(12)::after {
    animation-duration: 9s, 5501ms;
    animation-delay: 0ms, 5758ms;
  }
  
  @keyframes move12 {
    0% {
      transform: translateX(-11vw) translateY(-32vh) scale(0.45);
    }
    5.5555555556% {
      transform: translateX(13vw) translateY(41vh) scale(0.8);
    }
    11.1111111111% {
      transform: translateX(49vw) translateY(-24vh) scale(0.87);
    }
    16.6666666667% {
      transform: translateX(-36vw) translateY(14vh) scale(0.39);
    }
    22.2222222222% {
      transform: translateX(-7vw) translateY(-42vh) scale(0.74);
    }
    27.7777777778% {
      transform: translateX(19vw) translateY(34vh) scale(0.39);
    }
    33.3333333333% {
      transform: translateX(45vw) translateY(-19vh) scale(0.83);
    }
    38.8888888889% {
      transform: translateX(36vw) translateY(-25vh) scale(0.99);
    }
    44.4444444444% {
      transform: translateX(16vw) translateY(-17vh) scale(0.6);
    }
    50% {
      transform: translateX(-42vw) translateY(-43vh) scale(0.49);
    }
    55.5555555556% {
      transform: translateX(-8vw) translateY(-45vh) scale(0.32);
    }
    61.1111111111% {
      transform: translateX(-35vw) translateY(46vh) scale(0.55);
    }
    66.6666666667% {
      transform: translateX(12vw) translateY(-13vh) scale(0.36);
    }
    72.2222222222% {
      transform: translateX(-42vw) translateY(-25vh) scale(0.26);
    }
    77.7777777778% {
      transform: translateX(-21vw) translateY(30vh) scale(0.65);
    }
    83.3333333333% {
      transform: translateX(8vw) translateY(42vh) scale(0.51);
    }
    88.8888888889% {
      transform: translateX(-42vw) translateY(-5vh) scale(0.99);
    }
    94.4444444444% {
      transform: translateX(10vw) translateY(-33vh) scale(0.62);
    }
    100% {
      transform: translateX(41vw) translateY(50vh) scale(0.43);
    }
  }
  .firefly:nth-child(13) {
    animation-name: move13;
  }
  .firefly:nth-child(13)::before {
    animation-duration: 16s;
  }
  .firefly:nth-child(13)::after {
    animation-duration: 16s, 7911ms;
    animation-delay: 0ms, 3088ms;
  }
  
  @keyframes move13 {
    0% {
      transform: translateX(-17vw) translateY(-46vh) scale(0.27);
    }
    5.8823529412% {
      transform: translateX(17vw) translateY(4vh) scale(0.28);
    }
    11.7647058824% {
      transform: translateX(-18vw) translateY(-10vh) scale(0.99);
    }
    17.6470588235% {
      transform: translateX(37vw) translateY(-18vh) scale(0.81);
    }
    23.5294117647% {
      transform: translateX(41vw) translateY(-8vh) scale(0.47);
    }
    29.4117647059% {
      transform: translateX(-12vw) translateY(-16vh) scale(0.94);
    }
    35.2941176471% {
      transform: translateX(34vw) translateY(-46vh) scale(0.7);
    }
    41.1764705882% {
      transform: translateX(35vw) translateY(-2vh) scale(0.29);
    }
    47.0588235294% {
      transform: translateX(9vw) translateY(-19vh) scale(0.3);
    }
    52.9411764706% {
      transform: translateX(-20vw) translateY(-4vh) scale(0.79);
    }
    58.8235294118% {
      transform: translateX(10vw) translateY(44vh) scale(0.26);
    }
    64.7058823529% {
      transform: translateX(-47vw) translateY(2vh) scale(0.82);
    }
    70.5882352941% {
      transform: translateX(15vw) translateY(-13vh) scale(0.68);
    }
    76.4705882353% {
      transform: translateX(-6vw) translateY(5vh) scale(0.55);
    }
    82.3529411765% {
      transform: translateX(44vw) translateY(-47vh) scale(0.83);
    }
    88.2352941176% {
      transform: translateX(-29vw) translateY(22vh) scale(0.81);
    }
    94.1176470588% {
      transform: translateX(-6vw) translateY(-3vh) scale(0.72);
    }
    100% {
      transform: translateX(-43vw) translateY(-16vh) scale(0.82);
    }
  }
  .firefly:nth-child(14) {
    animation-name: move14;
  }
  .firefly:nth-child(14)::before {
    animation-duration: 10s;
  }
  .firefly:nth-child(14)::after {
    animation-duration: 10s, 7427ms;
    animation-delay: 0ms, 7063ms;
  }
  
  @keyframes move14 {
    0% {
      transform: translateX(18vw) translateY(41vh) scale(0.43);
    }
    3.7037037037% {
      transform: translateX(-15vw) translateY(21vh) scale(0.39);
    }
    7.4074074074% {
      transform: translateX(44vw) translateY(-47vh) scale(0.45);
    }
    11.1111111111% {
      transform: translateX(1vw) translateY(-38vh) scale(0.57);
    }
    14.8148148148% {
      transform: translateX(49vw) translateY(-21vh) scale(0.94);
    }
    18.5185185185% {
      transform: translateX(-23vw) translateY(-49vh) scale(0.92);
    }
    22.2222222222% {
      transform: translateX(48vw) translateY(48vh) scale(0.79);
    }
    25.9259259259% {
      transform: translateX(-30vw) translateY(5vh) scale(0.77);
    }
    29.6296296296% {
      transform: translateX(-44vw) translateY(31vh) scale(0.42);
    }
    33.3333333333% {
      transform: translateX(21vw) translateY(-38vh) scale(0.84);
    }
    37.037037037% {
      transform: translateX(25vw) translateY(16vh) scale(0.98);
    }
    40.7407407407% {
      transform: translateX(44vw) translateY(1vh) scale(0.5);
    }
    44.4444444444% {
      transform: translateX(-15vw) translateY(28vh) scale(0.72);
    }
    48.1481481481% {
      transform: translateX(25vw) translateY(-46vh) scale(0.96);
    }
    51.8518518519% {
      transform: translateX(37vw) translateY(-28vh) scale(0.84);
    }
    55.5555555556% {
      transform: translateX(-2vw) translateY(16vh) scale(0.27);
    }
    59.2592592593% {
      transform: translateX(-28vw) translateY(20vh) scale(0.36);
    }
    62.962962963% {
      transform: translateX(-12vw) translateY(22vh) scale(0.27);
    }
    66.6666666667% {
      transform: translateX(30vw) translateY(-36vh) scale(0.73);
    }
    70.3703703704% {
      transform: translateX(40vw) translateY(32vh) scale(0.38);
    }
    74.0740740741% {
      transform: translateX(25vw) translateY(-20vh) scale(0.5);
    }
    77.7777777778% {
      transform: translateX(36vw) translateY(29vh) scale(0.57);
    }
    81.4814814815% {
      transform: translateX(34vw) translateY(20vh) scale(0.59);
    }
    85.1851851852% {
      transform: translateX(29vw) translateY(7vh) scale(0.27);
    }
    88.8888888889% {
      transform: translateX(17vw) translateY(20vh) scale(0.46);
    }
    92.5925925926% {
      transform: translateX(11vw) translateY(-20vh) scale(0.73);
    }
    96.2962962963% {
      transform: translateX(-12vw) translateY(49vh) scale(0.81);
    }
    100% {
      transform: translateX(-12vw) translateY(-7vh) scale(0.64);
    }
  }
  .firefly:nth-child(15) {
    animation-name: move15;
  }
  .firefly:nth-child(15)::before {
    animation-duration: 18s;
  }
  .firefly:nth-child(15)::after {
    animation-duration: 18s, 10623ms;
    animation-delay: 0ms, 2479ms;
  }
  
  @keyframes move15 {
    0% {
      transform: translateX(-6vw) translateY(-31vh) scale(0.79);
    }
    3.7037037037% {
      transform: translateX(23vw) translateY(-16vh) scale(0.62);
    }
    7.4074074074% {
      transform: translateX(-48vw) translateY(-28vh) scale(0.67);
    }
    11.1111111111% {
      transform: translateX(-2vw) translateY(36vh) scale(0.76);
    }
    14.8148148148% {
      transform: translateX(-30vw) translateY(-41vh) scale(0.68);
    }
    18.5185185185% {
      transform: translateX(9vw) translateY(-21vh) scale(0.38);
    }
    22.2222222222% {
      transform: translateX(36vw) translateY(41vh) scale(0.44);
    }
    25.9259259259% {
      transform: translateX(46vw) translateY(-42vh) scale(0.5);
    }
    29.6296296296% {
      transform: translateX(-26vw) translateY(35vh) scale(0.5);
    }
    33.3333333333% {
      transform: translateX(-49vw) translateY(-45vh) scale(0.92);
    }
    37.037037037% {
      transform: translateX(-42vw) translateY(-48vh) scale(0.44);
    }
    40.7407407407% {
      transform: translateX(-3vw) translateY(15vh) scale(0.68);
    }
    44.4444444444% {
      transform: translateX(24vw) translateY(42vh) scale(0.84);
    }
    48.1481481481% {
      transform: translateX(-46vw) translateY(23vh) scale(0.86);
    }
    51.8518518519% {
      transform: translateX(-28vw) translateY(-12vh) scale(0.65);
    }
    55.5555555556% {
      transform: translateX(-25vw) translateY(40vh) scale(0.9);
    }
    59.2592592593% {
      transform: translateX(30vw) translateY(20vh) scale(0.93);
    }
    62.962962963% {
      transform: translateX(30vw) translateY(0vh) scale(0.72);
    }
    66.6666666667% {
      transform: translateX(-38vw) translateY(33vh) scale(0.97);
    }
    70.3703703704% {
      transform: translateX(36vw) translateY(-30vh) scale(0.96);
    }
    74.0740740741% {
      transform: translateX(3vw) translateY(-30vh) scale(0.38);
    }
    77.7777777778% {
      transform: translateX(-4vw) translateY(18vh) scale(0.63);
    }
    81.4814814815% {
      transform: translateX(42vw) translateY(30vh) scale(0.26);
    }
    85.1851851852% {
      transform: translateX(33vw) translateY(-3vh) scale(0.78);
    }
    88.8888888889% {
      transform: translateX(31vw) translateY(-24vh) scale(0.47);
    }
    92.5925925926% {
      transform: translateX(-48vw) translateY(11vh) scale(0.82);
    }
    96.2962962963% {
      transform: translateX(-35vw) translateY(1vh) scale(0.43);
    }
    100% {
      transform: translateX(-32vw) translateY(13vh) scale(0.39);
    }
  }
  @keyframes drift {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes flash {
    0%, 30%, 100% {
      opacity: 0;
      box-shadow: 0 0 0vw 0vw yellow;
      /* opacity: 1;
      box-shadow: 0 0 2vw 0.4vw yellow; */
    }
    5% {
      opacity: 1;
      box-shadow: 0 0 2vw 0.4vw yellow;
    }
  }
  
  
  
  
  
  
  
  @keyframes btnImprimirAnimation {
    0% {
      color: #c3454a;
    }
    100% {
      color: green;
    }
  }
  
  .btnImprimir {
    animation: btnImprimirAnimation 2s ease-in 5s infinite alternate;
  }
  

  @keyframes btnSinalizadorRetiradaAnimation {
    0% {
      /* background-color: #c3454a; */
    }
    100% {
      background-color:#4DBEFF;
    }
  }
  
  .btnSinalizadorRetirada {
    animation: btnSinalizadorRetiradaAnimation 2s ease-in 5s infinite alternate;
  }

  @keyframes btnSinalizadorRetiradaAnimationOffline {
    0% {
      /* background-color: #c3454a; */
    }
    100% {
      background-color:#c3454a;
    }
  }
  
  .btnSinalizadorRetiradaOffline {
    animation: btnSinalizadorRetiradaAnimationOffline 2s ease-in 5s infinite alternate;
  }

  @keyframes iconSinalizadorRetiradaAnimation {
    0% {
      color: #4DBEFF
    }
    100% {
      color: black;
    }
  }
  
  .iconSinalizadorRetirada {
    animation: iconSinalizadorRetiradaAnimation 2s ease-in 5s infinite alternate;
  }